nav {
  background-color: black;
  color: white;
  text-align: center;
  border: 0.3rem solid darkgray; /* Use rem unit */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh; /* Proportional to viewport height */
  width: 100vw;
}

nav ul {
  list-style-type: none;
  margin: 14rem; /* 로고 좌측으로 */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav ul li {
  margin-right: 3rem;
  min-width: 10rem;
}

nav ul li:last-child {
  margin-right: auto; /* Adjusts margin to keep the layout even */
}

nav ul li a {
  text-decoration: none;
  color: white;
  font-size: 2rem; /* Use rem unit */
}

nav ul li a:hover {
  color: #ddd;
}

.menu-logo-left{
  display: flex;
  align-items: center;
  margin-left: 0rem;
}

.menu-logo-left img{
  max-height: 100%;
  width: 10vw;
  height: 8vh;
  margin-top: 0.5rem;
}

.menu-logo-right {
  cursor: pointer;
  position: absolute;
  right: 1em;
  top: 1em; /* Adjust as needed */
}

.menu-logo-right img{
  max-height: 100%;
  width: 4vw;
  height: 6vh;
  margin-top: -0.25rem;
}

/* Sidebar Styling */
.sidebar {
  position: fixed;
  left: -250px; /* Initial position off-screen */
  top: 0;
  width: 250px; /* Sidebar width */
  height: 100%;
  background-color: #f9f9f9;
  box-shadow: 0.3125em 0 0.625em rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease;
  z-index: 20;
}

.sidebar.open {
  left: 0; /* Slide in from left */
  font-size: 1.5rem;
}

.sidebar a {
  display: block;
  padding: 2em;
  text-decoration: none;
  color: black;
}

.sidebar a:hover {
  background-color: #ddd;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Tablet and smaller devices */
@media (max-width: 768px) {
  nav ul li {
      margin-right: 1rem; /* Smaller margin on tablets */
  }

  nav ul li a {
      font-size: 1rem; /* Smaller font size on tablets */
  }

  .menu-logo-left img,
  .menu-center-logo img {
      width: 6vw; /* Smaller logo on tablets */
      height: 6vh;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) and (orientation: landscape){
  nav {
    background-color: black;
    color: white;
    text-align: center;
    border: 0.3rem solid darkgray; /* Use rem unit */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh; /* Proportional to viewport height */
    width:100vw;
  }
  nav ul {
    list-style-type: none;
    margin-left: 11rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  
  nav ul li {
    margin-right: 1rem;
    z-index: 20;
    min-width: 7rem;
  }

  nav ul li a {
    font-size: 1.3125rem; /* Adjusted font size for landscape tablets */
  }

  .menu-logo-left{
    display: flex;
    align-items: left;
    margin-left: 5rem;
  }
  .menu-logo-left img{
    width: 8vw; /* Adjusted logo size for landscape tablets */
    height: 6vh;
    margin-top: 0.55rem;
  }

  .menu-logo-right {
    cursor: pointer;
    position: absolute;
    right: 2em;
    top: 0.98rem; /* Adjust as needed */
  }
  
  .menu-logo-right img{
    max-height: 100%;
    width: 4vw;
    height: 6vh;
    margin-top: -0.7rem;
  }
  
  .sidebar {
    position: fixed;
    left: -250px; /* Initial position off-screen */
    top: 0;
    width: 250px; /* Sidebar width */
    height: 100%;
    background-color: #f9f9f9;
    box-shadow: 0.3125em 0 0.625em rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
  }
  
  .sidebar.open {
    left: 0; /* Slide in from left */
  }
  
  .sidebar a {
    display: block;
    padding: 1em;
    text-decoration: none;
    color: black;
  }
  
  .sidebar a:hover {
    background-color: #ddd;
  }

  .switch {
    position: relative;
    display: inline-block;
    left: 5rem;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
}

/* Mobile devices */
@media (max-width: 600px) {
  nav {
    background-color: black;
    color: white;
    text-align: center;
    border: 0.3rem solid darkgray; /* Use rem unit */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh; /* Proportional to viewport height */
    width:100vw;
  }
  nav ul {
    list-style-type: none;
    margin-left: 15rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  
  nav ul li {
    margin-right: 2rem;
    z-index: 20;
    min-width: 10rem;
  }

  nav ul li a {
    font-size: 1.5rem; /* Adjusted font size for landscape tablets */
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
}