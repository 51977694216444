.marketing-purpose-consent-container {
    background-color: white;
    color: black;
    padding: 20px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
  }
  
  .marketing-purpose-consent-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .marketing-purpose-consent-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .marketing-purpose-consent-container th,
  .marketing-purpose-consent-container td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 1rem;
  }
  
  .marketing-purpose-consent-container th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .marketing-purpose-consent-container td {
    background-color: #fff;
  }
  
  @media (max-width: 768px) {
    .marketing-purpose-consent-container h1 {
      font-size: 20px;
    }
  
    .marketing-purpose-consent-container th,
    .marketing-purpose-consent-container td {
      font-size: 12px;
      padding: 6px;
    }
  }
  
  @media (max-width: 480px) {
    .marketing-purpose-consent-container h1 {
      font-size: 18px;
    }
  
    .marketing-purpose-consent-container th,
    .marketing-purpose-consent-container td {
      font-size: 10px;
      padding: 4px;
    }
  }
  