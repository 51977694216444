.footer-container {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid white; /* Add white border bottom */
  width : 103%;
}

.footer-links a {
  color: white; /* Link color */
  text-decoration: none; /* Remove underline */
}

.footer-links a:visited {
  color: white; /* Visited link color */
}

.footer-links a:hover {
  text-decoration: underline; /* Underline on hover */
}

.footer-sections {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Distribute space between sections */
}

.section1, .section2, .section3 {
  flex: 1;
  padding: 0 15rem;
}

.section1 {
  text-align: left;
  margin-left: 20rem;
  min-width: 40rem;
}

.section2, .section3 {
  text-align: center;
  margin-left: -80rem;
  min-width: 30rem;
}

.footer-content p {
  margin: 0.5rem 0;
}

.footer-content a {
  margin: 0.5rem 0;
  color: white; /* Ensure other links are also white */
}

.social-media-links a {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  display: inline-block;
}

.social-media-links a:hover {
  text-decoration: underline;
}

@media (min-width: 1024px) and (max-width: 1280px) and (orientation: landscape) {
  .footer-container {
    padding: 0.75rem 0;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid white; /* Add white border bottom */
    width : 100%;
  }
  
  .footer-links a {
    font-size: 0.8rem;
    color: white; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .footer-links a:visited {
    color: white; /* Visited link color */
  }
  
  .footer-links a:hover {
    text-decoration: underline; /* Underline on hover */
  }

  .section1, .section2, .section3 {
    flex: 1;
    padding: 0 0rem;
  }

  .section1 {
    text-align: left;
    margin-left: 15rem;
    min-width: 40rem;
  }
  
  .section2, .section3 {
    text-align: center;
    margin-left: -35rem;
    min-width: 30rem;
  }

  .footer-content p {
    margin: 0.5rem 0;
    font-size: 0.8rem;
  }

  .footer-content a {
    margin: 0.5rem 0;
    color: white;
    font-size: 0.7rem;
  }

  .social-media-links a {
    color: white;
    text-decoration: none;
    margin: 0 1rem;
    display: inline-block;
  }

  .social-media-links a:hover {
    text-decoration: underline;
  }
}
