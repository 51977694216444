.title{
  color:white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
}

.title button{
  color:white;
  font-size: 2rem;
  background-color: red;
  cursor : pointer;
}

.coach-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 10rem;
}
.coach-list2 {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 10rem;
}

.coach-list button{
  width: 5rem;
  height: 2rem;
  margin-left: 8rem;
}
  .coach-list p{
  text-align: center;
  }
  .coach-list h3{
    text-align: center;
    }

  .coach-item {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 1em;
    margin-bottom: 2em; /* Adjust as needed */
    border-radius: 0.5em;
    width: 20rem;
}
  
  .coach-item h3 {
    margin-top: 0;
    color:black;
    font-size: 1.5rem; /* 24px if base font size is 16px */
  }
  .coach-item h3:hover {
    text-decoration: underline; /* Adds underline on hover */
  }
  
  .coach-item p {
    font-size: 1rem; /* 16px if base font size is 16px */
    margin-bottom: 0.5em;
  }
  
  .coach-item button {
    font-size: 0.875rem; /* 14px if base font size is 16px */
    padding: 0.5em 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 0.3em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .coach-item button:hover {
    background-color: #0056b3;
  }

  .member-item.no-members {
    text-align: center;
  }

  .title button:hover{
    background-color: #0056b3;
  }
@media (min-width: 1180px) and (max-width: 1400px) and (orientation: landscape) {

  .title{
    color:white;
    font-size: 3rem;
  }
  .title button{
    color:white;
    font-size: 1.5rem;
    background-color: red;
    cursor : pointer;
  }
  
  .coach-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 10rem;
  }
  .coach-list2 {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 7.5rem;
  }
  
  .coach-list button{
    width: 5rem;
    height: 2rem;
    margin-left: 8rem;
  }
  .coach-list p{
  text-align: center;
  }
  .coach-list h3{
    text-align: center;
  }

  .coach-item {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 1em;
    margin-bottom: 2em; /* Adjust as needed */
    border-radius: 0.5em;
    width: 20rem;
  }
    
  .coach-item h3 {
    margin-top: 0;
    color:black;
    font-size: 1rem; /* 24px if base font size is 16px */
  }
  .coach-item h3:hover {
    text-decoration: underline; /* Adds underline on hover */
  }
  
  .coach-item p {
    font-size: 1rem; /* 16px if base font size is 16px */
    margin-bottom: 0.5em;
  }
  
  .coach-item button {
    font-size: 0.875rem; /* 14px if base font size is 16px */
    padding: 0.5em 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 0.3em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .coach-item button:hover {
    background-color: #0056b3;
  }

  .member-item.no-members {
    text-align: center;
  }

  .title button:hover{
    background-color: #0056b3;
  }

}