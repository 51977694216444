
.before-container {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
  
  .before-container button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 20px;
    width: 20rem;
    height: 10rem;
    cursor: pointer; 
  }
  
  .before-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1024px) and (max-width: 1280px) and (orientation: landscape){
    .before-container {
        display: flex;
        flex-direction: column; /* Stack buttons vertically */
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        margin-top: -4rem;
      }
      
      .before-container button {
        margin: 10px;
        padding: 10px 20px;
        font-size: 20px;
        width: 15rem;
        height: 5rem;
        cursor: pointer; 
      }
      
      .before-container form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  }