.upload-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100vh;
    flex-direction: column;
    margin-left: 1.25rem; /* 20px 대신 상대 단위 사용 */
    margin-top: 10rem;
  }
  
  .upload-form {
    margin-bottom: 2.5rem; /* 40px 대신 상대 단위 사용 */
    margin-left: 10.75rem; /* 300px 대신 상대 단위 사용 */
    margin-top: 5.25rem; /* 100px 대신 상대 단위 사용 */
  }
  
  .upload-video {
    position: absolute;
    right: 20rem; /* 400px 대신 상대 단위 사용 */
    top: 8rem; /* 160px 대신 상대 단위 사용 */
    width: 33.75rem; /* 540px 대신 상대 단위 사용 */
    height: 45rem; /* 720px 대신 상대 단위 사용 */
    margin-left: 38.75rem;
  }
  
  .upload-metadata {
    color: white;
    margin-left: 15.75rem; /* 300px 대신 상대 단위 사용 */
    margin-bottom: 5.25rem;
  }
  
  .upload-input {
    width: 12rem; /* 150px 대신 상대 단위 사용 */
  }
  .dropdown-input {
    width: 12rem; /* 150px 대신 상대 단위 사용 */
    height: 2.3125rem;
  }
  
  .upload-textarea {
    width: 19.6875rem; /* 395px 대신 상대 단위 사용 */
    height: 2.5rem; /* 40px 대신 상대 단위 사용 */
  }

  .selected-video-container {
    flex-grow: 1; /* 남은 공간을 모두 사용합니다. */
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .spinner {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-Radius: 50%;
    margin-top: 1rem;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  @media (min-width: 1180px) and (max-width: 1400px) and (orientation: landscape){

  .upload-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100vh;
    flex-direction: column;
    margin-left: 1.25rem; /* 20px 대신 상대 단위 사용 */
    margin-top: 2rem;
  }

  .upload-form,
  .upload-metadata {
    margin-left: 6.725rem; /* 태블릿에서 조정 */
  }

  .upload-video {
    right: 10rem; /* 태블릿에서 조정 */
    top: 5rem; /* 태블릿에서 조정 */
    height: 30rem;
    width: 30rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    left: 0rem;
    top: 0rem;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 20px;
  }
  
}

  /* 태블릿 반응형 스타일 */
  @media (max-width: 768px) {
    .upload-form,
    .upload-metadata {
      margin-left: 1rem; /* 태블릿에서 조정 */
    }
  
    .upload-video {
      right: 1rem; /* 태블릿에서 조정 */
      top: 1rem; /* 태블릿에서 조정 */
      height: auto;
      width: 90%;
    }
    .upload-textarea {
      width: 17.6875rem; /* 395px 대신 상대 단위 사용 */
      height: 2.5rem; /* 40px 대신 상대 단위 사용 */
    }
  
  }

  /* 모바일 반응형 스타일 */
  @media (max-width: 600px) {
    .upload-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 100vh;
      flex-direction: column;
      margin-left: 1.25rem; /* 20px 대신 상대 단위 사용 */
      margin-top: 1rem;
    }
    .upload-form,
    .upload-metadata {
      margin-left: 1.5rem; /* 모바일에서 조정 */
    }
  
    .upload-video {
      right: 0.5rem; /* 모바일에서 조정 */
      top: 0.5rem; /* 모바일에서 조정 */
    }
    .upload-textarea {
      width: 15.6875rem; /* 395px 대신 상대 단위 사용 */
      height: 2.5rem; /* 40px 대신 상대 단위 사용 */
    }
  }