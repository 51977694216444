/* loginPage.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("/public/login2.png"); /* 이미지 경로 */
  background-size: cover; /* 배경 이미지가 컨테이너를 전체적으로 덮도록 설정 */
  background-position: center; /* 배경 이미지가 중앙에 위치하도록 설정 */
  background-repeat: no-repeat; /* 배경 이미지가 반복되지 않도록 설정 */
}

.container h2 {
  font-size: 1.5rem; /* Base font size for PC */
  color: white;
}

form {
  width: 100%;
  max-width: 25rem; /* Base max-width for PC */
  padding: 1.25rem;
  background-color: black;
  border-radius: 0.3125rem;
  border: 3px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

input {
  width: 95%;
  margin-bottom: 1rem;
  padding: 0.625rem;
  border: 1px solid #ccc;
  border-radius: 0.3125rem;
}

button {
  width: 100%;
  padding: 0.625rem;
  margin-top: 1.25rem;
  background-color: black;
  color: white;
  border: 3px solid white;
  border-radius: 0.3125rem;
  cursor: pointer;
}

button:hover {
  background-color: #292a2b;
}

.login-text {
  margin-top: 1rem;
  font-size: 0.875rem;
}

.login-text a {
  color: #007bff;
  text-decoration: none;
}

.login-text a:hover {
  text-decoration: underline;
}

/* Tablet devices */
@media (max-width: 768px) {
  .container h2 {
      font-size: 1.25rem;
  }

  form {
      max-width: 20rem;
      padding: 1rem;
  }

  input,
  button {
      padding: 0.5rem;
  }

  .login-text {
      font-size: 0.8rem;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) and (orientation: landscape) {
  .container h2 {
    font-size: 1.4rem; /* Adjusted for better readability in landscape */
  }

  form {
    max-width: 22rem; /* Slightly increased width for landscape */
    padding: 1rem; /* Consistent padding with portrait */
  }

  input,
  button {
    padding: 0.55rem; /* Slightly increased padding for larger touch targets */
  }

  .login-text {
    font-size: 0.85rem; /* A size between mobile and base font size */
  }
}

/* Mobile devices */
@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("/public/black.png"); /* 이미지 경로 */
    background-position: center; /* 배경 이미지가 중앙에 위치하도록 설정 */
    background-repeat: no-repeat; /* 배경 이미지가 반복되지 않도록 설정 */
  }
  
  .container h2 {
    font-size: 1rem; /* Base font size for PC */
    color: white;
  }
  form {
      max-width: 18rem;
      padding: 0.5rem;
  }

  input,
  button {
      padding: 0.4rem;
  }

  .login-text {
      font-size: 0.75rem;
  }
}