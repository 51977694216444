/* styles/compareanalyzePage.css */

body {
  background-color: black;
}
.CApp {
  text-align: center;
}
.CApp-title {
  font-size: 1.25rem;
  color: white;
}
.Cvideo-container {
  display: flex;
  justify-content: space-between;
}
.Cvideo-player1 {
  flex: 1;
  padding-left: 1.25rem; /* 20px converted */
  margin-top: 2.5rem;
  margin-bottom: 10rem;
}
.Cvideo-player2 {
  flex: 1;
  padding-left: 48.75rem; /* 780px converted */
  margin-top: 2.5rem; /* Same as .Cvideo-player1 */
}
.Cgraph-container {
  position: absolute;
  margin-top: 43.5rem; /* 200px converted */
  flex: 1;
  padding-left: 10.5rem; /* 200px converted */
}

.Csteps-buttons {
  position: absolute;
  top: 5.5rem; /* 100px converted */
  left: 39.5rem; /* 600px converted */
  display: flex;
}

.Cstep-button {
  color: white;
  background-color: black;
  min-width: 3.5rem;
  margin-right: 2rem; /* 25px converted */
  position: relative;
}

.Csteps-buttons .Cstep-button:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 3.3rem; /* 버튼 간격의 절반 */
  transform: translateY(-50%);
  width: 2rem; /* 버튼 간격과 동일하게 설정 */
  height: 5px;
  background-color: white; /* 선 색상 */
}

.Cgraph-checkbox {
  position: absolute;
  top: -40rem; /* 20px converted */
  left: 55.125rem; /* 470px converted */
  display: flex;
}

.Cplayback-buttons {
  position: absolute;
  display: flex; 
  justify-content: center;
  margin-top: 48rem; /* -90px converted */
  margin-left: 43.875rem; /* 680px converted */
  width: 33.75rem; /* 540px converted */
}

.Cplayback-buttons button {
  color: black;
  background-color: white;
  margin: 0 0.3125rem; /* 5px converted */
}

.Cplayback-buttons button + button {
  margin-left: 0.625rem; /* 10px converted */
}

.Cslider-container1 {
  position: absolute;
  margin-top: 51.5rem; /* 850px converted */
  margin-left: 1.5rem; /* 20px converted */
  width: 33.75rem; /* 540px converted */
}

.Cslider-container2 {
  position: absolute;
  margin-top: 51.5rem; /* Same as .Cslider-container1 */
  margin-left: 84.5rem; /* 1350px converted */
  width: 33.75rem; /* Same as .Cslider-container1 */
}

.Cslider-container3 {
  position: absolute;
  margin-top: 51.5rem; /* 840px converted */
  margin-left: 43.75rem; /* 700px converted */
  width: 33.75rem; /* Same as .Cslider-container1 */
}

.rc-slider {
  width: 100%;
}

.rc-slider-handle {
  border-color: white;
  background-color: white;
}

.rc-slider-rail {
  background-color: white;
}
    
  
.Clabel {
  color:white;
  display: inline-block;
  margin-left: 0rem; /* 5px converted */
  padding-left: 0rem;
  font-size: 1.25rem; /* 20px converted */
}

.Ccheckbox-label-pair {
  color: white;
  display: flex;
  align-items: center;
  margin-top: 0;
  gap: 1rem;
}

.Ccheckbox-label-pair2 {
  color: white;
  display: flex;
  align-items: center;
  margin-top: -4rem;
  margin-left: 10rem; /* 80px converted */
  margin-left: 0;
}

.Ccheckbox-label-pair3 {
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  left: 0rem; /* 100px converted */
  top: 0rem; /* -40px converted */
}

.Coption-label {
  margin-top: -0.9375rem; /* Same as .Clabel */
  margin-left: 0.3125rem; /* Same as .Clabel */
  margin-right: 0.9375rem; /* Same as .Clabel */
  font-size: 1.25rem; /* Same as .Clabel */
}

.Coption-label-pair {
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: 7.5rem; /* 120px converted */
  margin-right: 0.9375rem; /* Same as .Clabel */
  left: 16.25rem; /* 260px converted */
}

.Cbackangle-display1 {
  position: absolute;
  top: -40rem; /* -260px converted */
  right: -20.6125rem; /* 857px converted */
  padding: 0.125rem; /* 10px converted */
  background-color: white;
  color: black;
  min-width: 8rem;
  font-size: 0.75rem; /* Zero remains the same */
}

.Cbackangle-display2 {
  position: absolute;
  top: -40rem; /* Same as .Cbackangle-display1 */
  right: -105.5625rem; /* -473px converted */
  padding: 0.125rem; /* Same as .Cbackangle-display1 */
  background-color: white;
  color: black;
  min-width: 8rem;
  font-size: 0.75rem; /* Zero remains the same */
}
.Cvideo-select1 {
  position: absolute;
  left: 7.375rem; /* 150px converted */
  top: 5.5rem; /* 80px converted */
}

.Cvideo-select1 label {
  color: white;
  font-size: 1.25rem; /* 20px converted */
  margin-right: 0.625rem; /* 10px converted */
}

.Cvideo-select1 select {
  width: 12.5rem; /* 200px converted */
  height: 1.875rem; /* 30px converted */
  font-size: 0.875rem; /* 14px converted */
  border: 1px solid #ccc;
  border-radius: 0.25rem; /* 4px converted */
  padding: 0.3125rem; /* 5px converted */
}

.Cvideo-select2 {
  display: flex;
  align-items: center;
  position: absolute;
  left: 88.625rem; /* 1450px converted */
  top: 5.5rem; /* 80px converted */
}

.Cvideo-select2 label {
  color: white;
  font-size: 1.25rem; /* 20px converted */
  margin-right: 0.625rem; /* 10px converted */
  min-width: 10.125rem;
}

.Cvideo-select2 select {
  width: 12.5rem; /* 200px converted */
  height: 1.875rem; /* 30px converted */
  font-size: 0.875rem; /* 14px converted */
  border: 1px solid #ccc;
  border-radius: 0.25rem; /* 4px converted */
  padding: 0.3125rem; /* 5px converted */
}

.video-name1 {
  position: absolute;
  top: 5.5rem; /* 원하는 위치로 조정 */
  left: 18rem;
  transform: translateX(-50%);
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  z-index: 1;
  pointer-events: none; /* 비디오 이름을 클릭할 수 없도록 설정 */
}

.video-name2 {
  position: absolute;
  top: 5.5rem; /* 원하는 위치로 조정 */
  left: 104rem;
  transform: translateX(-50%);
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  z-index: 1;
  min-width: 20rem;
  pointer-events: none; /* 비디오 이름을 클릭할 수 없도록 설정 */
}

.Cchooseone-button {
  position: absolute;
  top: -0.5rem; /* -70px converted */
  left: 29rem; /* 550px converted */
  display: flex;
  z-index: 2;
}

.Cchooseone-button button {
  color: black;
  background-color: white;
  max-width: 4.5rem;
  max-height: 2.5rem;
  margin-right: 0.5rem; /* 40px converted */
  display: flex;
  justify-content: center;   /* 가로 방향 중앙 정렬 */
  align-items: center;
  text-align: center;
}

.Cbackangle-button {
  position: absolute;
  top: -12.5rem; /* -70px converted */
  left: 40rem; /* 900px converted */
  display: flex;
}

.Cbackangle-button button {
  background-color: red;
  margin-right: 1rem; /* 40px converted */
  min-width: 8rem;
}

.Cbackangle-button label{
  font-size: 1.3125rem;
}
.Cbackangle-button2 {
  position: absolute;
  top: 1.5rem; /* -70px converted */
  left: -11.5rem; /* 900px converted */
  display: flex;
}

.Cbackangle-button2 button {
  background-color: red;
  margin-right: 2rem; /* 40px converted */
  min-width: 8rem;
}

.Cbackangle-button2 label{
  font-size: 1.3125rem;
}

.Cchoose1-buttons {
  position: absolute;
  top: 52rem; /* 560px converted */
  left: 9.375rem; /* 150px converted */
  display: flex;
  z-index: 2;
}

.Cchoose1-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.625rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 6.25rem; /* 100px converted */
}

.Cchoose2-buttons {
  position: absolute;
  top: 52rem; /* 560px converted */
  left: 92rem; /* 1500px converted */
  display: flex;
}

.Cchoose2-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.625rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 6.25rem; /* 100px converted */
}


.coachCchoose1-buttons {
  position: absolute;
  top: 3.125rem; /* 560px converted */
  left: 9.375rem; /* 150px converted */
  display: flex;
  z-index: 2;
}

.coachCchoose1-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.625rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 6.25rem; /* 100px converted */
}

.coachCchoose2-buttons {
  position: absolute;
  top: 3.125rem; /* 560px converted */
  left: 92rem; /* 1500px converted */
  display: flex;
}

.coachCchoose2-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.625rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 6.25rem; /* 100px converted */
}

.thumbnail-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.25rem; /* 20px converted */
}

.thumbnail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.25rem; /* 20px converted */
}

.thumbnail-title {
  margin-top: 0.625rem; /* 10px converted */
}

.Cflip1-buttons {
  position: absolute;
  top: 52rem; /* 837px converted */
  left: 21.875rem; /* 350px converted */
  display: flex;
  min-width: 6.25rem;
  z-index: 2;
}

.Cflip2-buttons {
  position: absolute;
  top: 52rem; /* 837px converted */
  left: 104.25rem; /* 1700px converted */
  min-width: 6.25rem;
  display: flex;
}

.Cchoosetable-button {
  position: absolute;
  top: -9.5rem; /* -70px converted */
  left: 38.5rem; /* 550px converted */
  display: flex;
  max-height: 4rem;
}

.Cchoosetable-button button {
  color: black;
  background-color: white;
  margin-right: 2rem; /* 40px converted */
}

.Csummary-container {
  position: absolute;
  margin: 1rem;
  width: 40rem;
  left: 39.75rem;
  top: -32rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  color: yellowgreen;
}

.table-summary th {
  border: 3px solid darkgray;
  text-align: center;
  padding: 0.625rem;
  font-size: 1rem;
}

.table-summary td {
  border: 3px solid darkgray;
  text-align: center;
  padding: 0.125rem;
  font-size: 1.6125rem;
}

.table-summary thead th {
  background-color: gray;
  color: white;
  position: sticky;
  top: 0;
}

tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

tbody tr:hover {
  background-color: #ddd;
}

tbody tr td:first-child {
  font-weight: bold;
}

.Cperstep-container {
  position: absolute;
  margin: 1rem;
  width: 40.125rem;
  left: 40rem;
  top: -31.5rem;
}

.table-perstep th {
  border: 3px solid darkgray;
  text-align: center;
  padding: 0.3rem;
  font-size: 1.3125rem;
}

.table-perstep td {
  border: 3px solid darkgray;
  text-align: center;
  padding: 0.3125rem;
  font-size: 1.3125rem;
}

/* 스위치 컨테이너 */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 10px; /* 텍스트와 스위치 간격 조절 */
}

.switch-label {
  color: white; /* 텍스트 색상 */
  font-size: 1.25rem;
}

@media (min-width: 1180px) and (max-width: 1400px) and (orientation: landscape){
.Cvideo-player1 {
  flex: 1;
  padding-left: 1.5rem; /* 20px converted */
  margin-top: 4rem;
  margin-bottom: 10rem;
}

.Cvideo-player2 {
  flex: 1;
  padding-left: 38.5rem; /* 780px converted */
  margin-top: 4rem; /* Same as .Cvideo-player1 */
}

.Cgraph-container {
  position: absolute;
  margin-top: 32rem; /* 200px converted */
  flex: 1;
  padding-left: 7.5rem; /* 200px converted */
}

.Csteps-buttons {
  position: absolute;
  top: 3rem; /* 100px converted */
  left: 22.5rem; /* 600px converted */
  display: flex;
}

.Csteps-buttons button {
  color: white;
  background-color: black;
  min-width: 3rem;
  margin-right: 1.2rem; /* 25px converted */
  font-size: 0.75rem;
}

.Csteps-buttons .Cstep-button:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 2.75rem; /* 버튼 간격의 절반 */
  transform: translateY(-50%);
  width: 1.3rem; /* 버튼 간격과 동일하게 설정 */
  height: 3px;
  background-color: white; /* 선 색상 */
}

.Cgraph-checkbox {
  position: absolute;
  top: -28rem; /* 20px converted */
  left: 34rem; /* 470px converted */
  display: flex;
}
.Clabel {
  color:white;
  display: inline-block;
  margin-left: 0rem; /* 5px converted */
  padding-left: 0rem;
  font-size: 0.875rem; /* 20px converted */
}

.Ccheckbox-label-pair {
  color: white;
  display: flex;
  align-items: center;
  margin-top: 0;
  left: 10rem;
  gap: 1rem;
}

.Ccheckbox-label-pair2 {
  color: white;
  display: flex;
  align-items: center;
  margin-top: -2rem;
  margin-left: 20rem; /* 80px converted */
  margin-left: 0;
}

.Ccheckbox-label-pair3 {
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  left: 0rem; /* 100px converted */
  top: 0.5rem; /* -40px converted */
}
.Cplayback-buttons {
  position: absolute;
  display: flex; 
  justify-content: center;
  margin-top: 34rem; /* -90px converted */
  margin-left: 25.625rem; /* 680px converted */
  width: 25.5rem; /* 540px converted */
  height: 2.5rem;
}

.Cplayback-buttons button {
  color: black;
  background-color: white;
  margin: 0 0.125rem; /* 5px converted */
  font-size: 0.875rem;
}

.Cplayback-buttons button + button {
  margin-left: 0.25rem; /* 10px converted */
}

.Cslider-container1 {
  position: absolute;
  margin-top: 32.125rem; /* 850px converted */
  margin-left: 2rem; /* 20px converted */
  width: 16.5rem; /* 540px converted */
}

.Cslider-container2 {
  position: absolute;
  margin-top: 32.125rem; /* Same as .Cslider-container1 */
  margin-left: 57.5rem; /* 1350px converted */
  width: 16.5rem; /* Same as .Cslider-container1 */
}

.Cslider-container3 {
  position: absolute;
  margin-top: 37.125rem; /* 840px converted */
  margin-left: 25.75rem; /* 700px converted */
  width: 25.5rem; /* Same as .Cslider-container1 */
}

.Cbackangle-display1 {
  position: absolute;
  top: -29.5rem; /* -260px converted */
  left: 10rem; /* 857px converted */
  padding: 0.125rem; /* 10px converted */
  background-color: white;
  color: black;
  max-width: 2rem;
  font-size: 0.625rem; /* Zero remains the same */
}

.Cbackangle-display2 {
  position: absolute;
  top: -29.5rem; /* Same as .Cbackangle-display1 */
  right: -66rem; /* -473px converted */
  padding: 0.125rem; /* Same as .Cbackangle-display1 */
  background-color: white;
  color: black;
  max-width: 2rem;
  font-size: 0.625rem; /* Zero remains the same */
}

.Cvideo-select1 {
  position: absolute;
  left: 5.375rem; /* 150px converted */
  top: 3.5rem; /* 80px converted */
}

.Cvideo-select1 label {
  color: white;
  font-size: 0.875rem; /* 20px converted */
  margin-right: 0.625rem; /* 10px converted */
}

.Cvideo-select1 select {
  width: 1.5rem; /* 200px converted */
  height: 1rem; /* 30px converted */
  font-size: 0.5rem; /* 14px converted */
  border: 1px solid #ccc;
  border-radius: 0.25rem; /* 4px converted */
  padding: 0.3125rem; /* 5px converted */
}

.Cvideo-select2 {
  display: flex;
  align-items: center;
  position: absolute;
  left: 60.625rem; /* 1450px converted */
  top: 3.625rem; /* 80px converted */
}

.Cvideo-select2 label {
  color: white;
  font-size: 0.875rem; /* 20px converted */
  margin-right: -1.5rem; /* 10px converted */
  min-width: 10.125rem;
}

.Cvideo-select2 select {
  width: 1.5rem; /* 200px converted */
  height: 1rem; /* 30px converted */
  font-size: 0.5rem; /* 14px converted */
  border: 1px solid #ccc;
  border-radius: 0.25rem; /* 4px converted */
  padding: 0.3125rem; /* 5px converted */
}

.video-name1 {
  position: absolute;
  top: 4rem; /* 원하는 위치로 조정 */
  left: 10rem;
  transform: translateX(-50%);
  color: white;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  z-index: 1;
  pointer-events: none; /* 비디오 이름을 클릭할 수 없도록 설정 */
}

.video-name2 {
  position: absolute;
  top: 4rem; /* 원하는 위치로 조정 */
  left: 69.5rem;
  transform: translateX(-50%);
  color: white;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  z-index: 1;
  pointer-events: none; /* 비디오 이름을 클릭할 수 없도록 설정 */
}

.Cchooseone-button {
  position: absolute;
  top: -0.5rem; /* -70px converted */
  left: 18.5rem; /* 550px converted */
  display: flex;
}

.Cchooseone-button button {
  color: black;
  background-color: white;
  margin-right: 0.5rem; /* 40px converted */
  font-size: 0.6125rem;
  height: 2rem;
}

.Cbackangle-button {
  position: absolute;
  top: -11rem; /* -70px converted */
  left: 20rem; /* 900px converted */
  display: flex;
}

.Cbackangle-button button {
  background-color: red;
  margin-right: 1.5rem; /* 40px converted */
  font-size: 0.875rem;
  min-width: 6rem;
}

.Cbackangle-button label{
  font-size: 0.875rem;
  margin-left: 2rem;
}


.Cbackangle-button2 {
  position: absolute;
  top: 0rem; /* -70px converted */
  left: -12rem; /* 900px converted */
  display: flex;
}

.Cbackangle-button2 label{
  font-size: 0.875rem;
  margin-left: 1rem;
}

.Cbackangle-button2 button {
  background-color: red;
  margin-right: 1.5rem; /* 40px converted */
  font-size: 0.875rem;
  min-width: 6rem;
}

.Cchoose1-buttons {
  position: absolute;
  top: 31rem; /* 560px converted */
  left: 3rem; /* 150px converted */
  display: flex;
}

.Cchoose1-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.125rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  min-width: 5rem; /* 100px converted */
}

.Cchoose2-buttons {
  position: absolute;
  top: 31rem; /* 560px converted */
  left: 59rem; /* 1500px converted */
  display: flex;
}

.Cchoose2-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.125rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  min-width: 5rem; /* 100px converted */
}

.coachCchoose1-buttons {
  position: absolute;
  top: -4.45rem; /* 560px converted */
  left: 3rem; /* 150px converted */
  display: flex;
}

.coachCchoose1-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.125rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  min-width: 5rem; /* 100px converted */
}

.coachCchoose2-buttons {
  position: absolute;
  top: -4.45rem;/* 560px converted */
  left: 59rem; /* 1500px converted */
  display: flex;
}

.coachCchoose2-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.125rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  min-width: 5rem; /* 100px converted */
}

.Cflip1-buttons {
  position: absolute;
  top: 31rem; /* 837px converted */
  left: 12rem; /* 350px converted */
  min-width: 5rem;
  display: flex;
}
.Cflip1-buttons button{
  font-size:0.875rem;
}

.Cflip2-buttons {
  position: absolute;
  top: 31rem; /* 837px converted */
  left: 67.875rem; /* 1700px converted */
  min-width: 5rem;
  display: flex;
}
.Cflip2-buttons button{
  font-size:0.875rem;
}

.Cchoosetable-button {
  position: absolute;
  top: -9.5rem; /* -70px converted */
  left: 23rem; /* 550px converted */
  display: flex;
  max-height: 4rem;
}

.Cchoosetable-button button {
  color: black;
  background-color: white;
  margin-right: 2rem;
  font-size: 0.6125rem;
}

.Csummary-container {
  position: absolute;
  width: 40rem;
  left: 17.75rem;
  top: -23.3125rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  color: yellowgreen;
}

.table-summary th {
  border: 3px solid darkgray;
  text-align: center;
  padding: 0.625rem;
  font-size: 1rem;
}

.table-summary td {
  border: 3px solid darkgray;
  text-align: center;
  padding: 0.3rem;
  font-size: 0.8rem;
}

.table-summary thead th {
  background-color: gray;
  color: white;
  position: sticky;
  top: 0;
}

tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

tbody tr:hover {
  background-color: #ddd;
}

tbody tr td:first-child {
  font-weight: bold;
}

.Cperstep-container {
  position: absolute;
  margin: 1rem;
  width: 40.125rem;
  left: 17.5rem;
  top: -23.5rem;
}

.table-perstep th {
  border: 3px solid darkgray;
  text-align: center;
  font-size: 0.875rem;
}

.table-perstep td {
  border: 3px solid darkgray;
  padding : 0.2rem;
  text-align: center;
  font-size: 0.875rem;
}
}