body {
  background-color: black;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparent;
  margin-top: -5rem;
}

.container h3 {
  font-size: 1.5rem; /* Adjusted to rem unit */
  margin-bottom: 0.5rem; /* Adjusted to rem unit */
  color: white;
}

input {
  width: 95%;
  margin-bottom: 1rem; /* Adjusted to rem unit */
  padding: 0.625rem; /* Adjusted to rem unit */
  border: 1px solid #ccc;
  border-radius: 0.3125rem; /* Adjusted to rem unit */
}

.signup-text {
  margin-top: 1.25rem; /* Adjusted to rem unit */
  color: white;
  font-size: 1rem; /* Adjusted to rem unit */
}

.signup-text a {
  color: #007bff;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

.container form .error-text{
  color: red;
  font-size: 0.875rem; /* Adjusted to rem unit */
  margin-top: 0.3125rem; /* Adjusted to rem unit */
}
.container form .success-text {
  color: green;
  font-size: 0.875rem; /* Adjusted to rem unit */
  margin-top: 0.3125rem; /* Adjusted to rem unit */
}

/* Tablet */
@media (max-width: 768px) {
  .container h3 {
      font-size: 1.25rem; /* Smaller font size on tablets */
  }

  input {
      padding: 0.5rem; /* Smaller padding on tablets */
  }

  .signup-text {
      font-size: 0.875rem; /* Smaller font size on tablets */
  }
}

@media (min-width: 1024px) and (max-width: 1280px) and (orientation: landscape) {

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: transparent;
    margin-top: -5rem;
  }

  .container h3 {
    font-size: 1.5rem;
  }

  input {
    padding: 0.625rem;
  }

  .signup-text {
    font-size: 1rem;
  }

}