.App {
    text-align: center;
  }
  
  .App-title {
    font-size: 1.25rem;
  }

  /* ---------------영상, 슬라이더, 배속, 재생, 일시정지, 프레임&척추각, 드롭다운--------------- */
  /* video-player 안에 위에 있는 class들이 포함 됨 */
  .video-player {
    flex: 1;
    padding-left: 0;
    margin-top: 2.5rem;
    margin-left:4rem;
    margin-bottom: 10rem;
  }

  .slider-container {
    position: absolute;
    margin-top: 0.3125rem;
    margin-left: 0rem;
    width: 33.75rem;
  }
  .playback-buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    width: 33.75rem;
    z-index: 5;
  }
  
  .playback-buttons button {
    background-color: black;
    margin: 0 0.3125rem;
  }
  
  .playback-buttons button + button {
    margin-left: 0.625rem;
  }

  .backangle-display {
    position: absolute;
    top: -27rem;
    left: 28.125rem;
    padding: 0.625rem;
    min-width: 7rem;
    background-color: black;
    color: white;
    font-size: 0.875rem;
  }

  .video-select {
    position: relative;
    left: 3.375rem;
    top: -47rem;
  }
  
  .video-select label {
    font-size: 1.25rem;
    margin-right: 0.625rem;
    color: white;
  }
  
  .video-select select {
    width: 12.5rem;
    height: 1.875rem;
    font-size: 0.875rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    padding: 0.3125rem;
  }
  /* ---------------영상, 슬라이더, 배속, 재생, 일시정지, 프레임&척추각, 드롭다운--------------- */


  /*---------------스텝버튼, Detail & General 버튼---------------*/
  /* steps-buttons안에 detail버튼 */
  .steps-buttons {
    position: absolute;
    top: 8.125rem;
    left: 42.25rem;
    display: flex;
    align-items: center;
  }
  
  .steps-buttons button {
    background-color: black;
    margin-right: 3rem;
    font-size: 1.3125rem;
    min-width: 5rem;
    position: relative;
  }

  .steps-buttons > button {
    margin-right: 3rem;
  }

  .steps-buttons .step-button:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 4.8rem; /* 버튼 간격의 절반 */
    transform: translateY(-50%);
    width: 3rem; /* 버튼 간격과 동일하게 설정 */
    height: 5px;
    background-color: white; /* 선 색상 */
  }

  .option-button-container {
    position: absolute;
    top: 0rem;
    left: 65rem;
  }

  .draw{
    position: absolute;
    top: -3.625rem;
    left: -33rem;
    color: black;
    min-width: 6.5rem;
    z-index: 1;
  }

  .draw button{
    font-size: 1rem;
    min-width: 2rem;
  }

  .video-name {
    position: absolute;
    top: 8.5rem; /* 원하는 위치로 조정 */
    left: 23rem;
    transform: translateX(-50%);
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    z-index: 1;
    pointer-events: none; /* 비디오 이름을 클릭할 수 없도록 설정 */
  }

  .shape{
    position: absolute;
    top: 10rem;
    left: 9rem;
    color: red;
    min-width: 6.5rem;
    z-index: 1;
  }
  /*---------------스텝버튼, Detail & General 버튼---------------*/

  /*---------------테이블, 테이블 체크박스---------------*/
  .table-container {
    position: absolute;
    margin: 1rem;
    width: 78.125rem;
    left: 38rem;
    top: -19.25rem;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    color: yellowgreen;
  }
  
  /*테이블 lable*/
  th {
    border: 3px solid darkgray;
    text-align: center;
    padding: 0.125rem;
    font-size: 1.3125rem;
  }
  
  /*td는 값*/
  td { 
    border: 3px solid darkgray;
    text-align: center;
    padding: 0.625rem;
    font-size: 1.875rem;
  }
  
  thead th {
    background-color: gray;
    color: white;
    position: sticky;
    top: 0;
  }
  
  tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  tbody tr:hover {
    background-color: #ddd;
  }
  
  tbody tr td:first-child {
    font-weight: bold;
  }

  .table-checkbox {
    position: absolute;
    top: 14rem;
    left: 40.125rem;
    margin-left: 3.125rem;
    display: flex; /* 이 부분을 추가하여 체크박스들이 flex 아이템으로 작동하게 합니다 */
    flex-direction: row; /* 가로로 나열하기 위해 row로 변경합니다 */
    gap: 1rem; /* 체크박스 사이의 간격을 설정합니다 */
  }

  .labelR, .labelE, .labelS, .labelH, .labelK, .labelSP {
    margin-top: 0rem;
    margin-left: 0rem;
    margin-right: 0.5rem;
    font-size: 1.25rem;
    color: white;
  }

  .checkbox-label-pairR, .checkbox-label-pairE, .checkbox-label-pairS, .checkbox-label-pairH, .checkbox-label-pairK, .checkbox-label-pairSP {
    flex-direction: row;
    align-items: center;
    top: 25rem;
    margin-top: 0;
    margin-left: 0;
    gap: 1rem;
  }

  input[type="checkbox"] {
    margin: 0;
    vertical-align: middle;
  }
  /*---------------테이블, 테이블 체크박스---------------*/
  

  /*---------------그래프, 체크박스---------------*/
  .graph-container {
    position: absolute;
    margin-top: -26rem;
    flex: 1;
    padding-left: 23.875rem;
  }
  
  .graph-checkbox {
    position: absolute;
    top: -24rem;
    left: 32.125rem;
    margin-left: 3.125rem;
    display: flex;
    z-index: 5;
  }

  .graph-checkbox2 {
    position: absolute;
    top: 0rem;
    left: 43.125rem;
    margin-left: 14.125rem;
    display: flex;
  }

  .label{
    margin-top: 0rem;
    margin-left: 0rem;
    margin-right: 0.5rem;
    font-size: 1.25rem;
    color: white;
  }
  .general-label{
    margin-top: 0rem;
    margin-left: 0rem;
    margin-right: 0.5rem;
    font-size: 1.25rem;
    color: white;
  }
  
  .checkbox-label-pair{
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
    gap: 1rem;
  }

  input[type="checkbox"] {
    margin: 0;
    vertical-align: middle;
  }
  /*---------------그래프, 체크박스---------------*/



  .rc-slider {
    width: 100%;
  }
  
  .rc-slider-handle {
    border-color: red;
    background-color: red;
  }
  
  .rc-slider-rail {
    background-color: white;
  }


/*------------------------------------------------------------------------------*/
.choose-buttons {
  position: absolute;
  top: 4.5rem; /* 560px converted */
  left: 30.375rem; /* 150px converted */
  display: flex;
}

.choose-buttons button {
  color: white;
  background-color: black;
  margin-right: 0.625rem; /* 10px converted */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 6.25rem; /* 100px converted */
}

/* 스위치 컨테이너 */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/*------------------------------------------------------------------------------*/
@media (min-width: 1180px) and (max-width: 1650px) and (orientation: landscape) {
  /* ---------------영상, 슬라이더, 배속, 재생, 일시정지, 프레임&척추각, 드롭다운--------------- */
  /* video-player 안에 위에 있는 class들이 포함 됨 */
  .video-player {
    flex: 1;
    padding-left: 0;
    margin-top: 5rem;
    margin-bottom: 10rem;
  }

  .slider-container {
    position: absolute;
    margin-top: 0.5rem;
    margin-left: 0rem;
    width: 17rem;
  }
  .playback-buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: 17rem;
    height: 3rem;
    margin-left: 0rem;
    z-index: 5;
  }
  
  .playback-buttons button {
    background-color: black;
    margin: 0 0.125rem;
  }
  
  .playback-buttons button + button {
    margin-left: 0.25rem;
  }

  .backangle-display {
    position: absolute;
    top: -16.5rem;
    left: 15.25rem;
    padding: 0.3125rem;
    min-width: 4rem;
    background-color: black;
    color: white;
    font-size: 0.5rem;
  }

  .video-select {
    position: relative;
    left: 4.375rem;
    top: -26.5rem;
  }
  
  .video-select label {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: white;
  }
  
  .video-select select {
    width: 1.5rem;
    height: 1rem;
    font-size: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    padding: 0.3125rem;
  }
  /* ---------------영상, 슬라이더, 배속, 재생, 일시정지, 프레임&척추각, 드롭다운--------------- */


  /*---------------스텝버튼, Detail & General 버튼----------------*/
  /* steps-buttons안에 detail버튼 */
  .steps-buttons {
    position: absolute;
    top: 3rem;
    left: 25rem;
    display: flex;
    z-index: 5;
  }
  
  .steps-buttons button {
    background-color: black;
    margin-right: 2rem;
    min-width: 3rem;
    font-size: 0.8rem;
  }
  
  .steps-buttons .step-button:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 2.875rem; /* 버튼 간격의 절반 */
    transform: translateY(-50%);
    width: 2rem; /* 버튼 간격과 동일하게 설정 */
    height: 3px;
    background-color: white; /* 선 색상 */
  }

  .option-button-container {
    position: absolute;
    top: 0rem;
    left: 40rem;
    color: black;
  }

  .draw{
    position: absolute;
    top: -0.625rem;
    left: -22rem;
    z-index: 1;
    color: black;
    min-width: 5rem;
  }

  .video-name {
    position: absolute;
    top: 6.5rem; /* 원하는 위치로 조정 */
    left: 12.5rem;
    transform: translateX(-50%);
    color: white;
    padding: 0.5rem 1rem;
    font-size: 0.5rem;
    z-index: 1;
    pointer-events: none; /* 비디오 이름을 클릭할 수 없도록 설정 */
  }
  /*---------------스텝버튼, Detail & General 버튼---------------*/


  /*---------------테이블, 테이블 체크박스---------------*/
  .table-container {
    position: absolute;
    margin: 1rem;
    width: 48.125rem;
    left: 22rem;
    top: -13rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    color: yellowgreen;
  }
  
  /*테이블 lable*/
  th {
    border: 2px solid darkgray;
    text-align: center;
    padding: 0.1rem;
    font-size: 0.875rem;
  }
  
  /*테이블 값*/
  td {
    border: 3px solid darkgray;
    text-align: center;
    padding: 0rem;
    font-size: 1.5rem;
  }

  .table-checkbox {
    position: absolute;
    top: -3rem;
    left: 10rem;
    margin-left: 15rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  .table-checkbox input{
    position: absolute;
    max-width: 1rem;
  }

  .general-label{
    margin-top: 3rem;
    margin-right: -4rem;
    font-size: 1rem;
    min-width: 5rem;
    color: white;
  }

  .labelR, .labelE, .labelS, .labelH, .labelK, .labelSP {
    margin-top: 0.625rem;
    margin-left: 0.3125rem;
    margin-right: 0.9375rem;
    font-size: 1.5625rem;
    color: white;
  }

  .checkbox-label-pairR, .checkbox-label-pairE, .checkbox-label-pairS, .checkbox-label-pairH, .checkbox-label-pairK, .checkbox-label-pairSP {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10rem;
    margin-left: 1.5rem; /* 체크박스 사이 벌리기*/
  }
  /*---------------테이블, 테이블 체크박스---------------*/
  

  /*---------------그래프, 체크박스---------------*/
  .graph-container {
    position: absolute;
    margin-top: -17rem;
    flex: 1;
    padding-left: 13.875rem;
  }
  
  .graph-checkbox {
    position: absolute;
    top: -16.5rem;
    left: 20.125rem;
    margin-left: 3.125rem;
    display: flex;
  }

  .graph-checkbox2 {
    position: absolute;
    top: 5.5rem;
    left: 23.125rem;
    margin-left: -23.125rem;
    display: flex;
  }

  .label{
    margin-right: 1rem;
    font-size: 0.875rem;
    
    color: white;
  }
  
  .checkbox-label-pair{
    align-items: center;
    margin-top: 0;
    margin-left: -1rem;
    gap: 1rem;
  }

/*---------------그래프, 체크박스---------------*/
  .choose-buttons {
    position: absolute;
    top: 2.3125rem; /* 560px converted */
    left: 17rem; /* 150px converted */
    display: flex;
  }
  .choose-buttons button {
    color: white;
    background-color: black;
    margin-right: 0.625rem; /* 10px converted */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    min-width: 5.25rem; /* 100px converted */
  }
}
  @media (max-width: 768px) {
    /* Adjusting the App title font size */
    .App-title {
      font-size: 1rem;
    }
  
    /* Adjusting video container for smaller screens */
    .video-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    /* Adjusting video player styling */
    .video-player {
      flex: none;
      margin-top: 0;
      width: 100%;
    }
  
    /* Adjusting graph container styling */
    .graph-container {
      position: relative;
      margin-top: 1rem;
      padding-left: 0;
      width: 100%;
    }
  
    /* Adjusting step buttons layout */
    .steps-buttons {
      position: relative;
      top: 0;
      left: 0;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
    }
  
    /* Adjusting graph checkbox layout */
    .graph-checkbox {
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
    }
  
    /* Adjusting playback buttons layout */
    .playback-buttons {
      width: 100%;
      justify-content: space-around;
    }
  
    /* Adjusting slider container layout */
    .slider-container {
      width: 100%;
      margin-top: 1rem;
      margin-left: 0;
    }
  
    /* Adjusting table container for smaller screens */
    .table-container {
      width: 100%;
      left: 0;
      top: 0;
    }
  
    /* Adjusting general info layout */
    .general-info {
      text-align: center;
      padding: 0.5rem;
    }
  
    /* Adjusting checkbox-label pairs */
    .checkbox-label-pair, .checkbox-label-pairR, .checkbox-label-pairE, .checkbox-label-pairS, 
    .checkbox-label-pairH, .checkbox-label-pairK, .checkbox-label-pairSP {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 0;
      margin-left: 0;
      margin-top: 0.5rem;
    }
  
    /* Adjusting label styles */
    .label, .labelR, .labelE, .labelS, .labelH, .labelK, .labelSP {
      font-size: 1rem;
      margin-left: 0.2rem;
      margin-right: 0.5rem;
    }
  
    /* Adjusting video select dropdown */
    .video-select {
      left: 50%;
      transform: translateX(-50%);
      top: 1rem;
    }
  
    /* Adjusting option button container */
    .option-button-container {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  
    /* Adjusting table styling for readability */
    table, th, td {
      font-size: 0.8rem;
    }
  
    /* Adjusting backangle display */
    .backangle-display {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 600px) {
    .App-title {
      font-size: 0.9rem; /* Smaller font size for mobile screens */
    }
  
    .video-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .video-player {
      flex: none;
      margin-top: 0;
      width: 100%; /* Full width for better visibility on mobile */
    }
  
    .graph-container {
      position: relative;
      margin-top: 0.5rem;
      padding-left: 0;
      width: 100%;
    }
  
    .steps-buttons {
      position: relative;
      top: 0;
      left: 0;
      flex-direction: column;
      align-items: center;
      margin-top: 0.5rem;
    }
  
    .graph-checkbox {
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
    }
  
    .playback-buttons {
      width: 100%;
      justify-content: space-around;
    }
  
    .slider-container {
      width: 100%;
      margin-top: 0.5rem;
      margin-left: 0;
    }
  
    .table-container {
      width: 100%; /* Full width to maximize space */
      left: 0;
      top: 0;
      overflow-x: scroll; /* Allow horizontal scrolling for tables */
    }
  
    .general-info {
      text-align: center;
      padding: 0.5rem;
    }
  
    .checkbox-label-pair, .checkbox-label-pairR, .checkbox-label-pairE, .checkbox-label-pairS, 
    .checkbox-label-pairH, .checkbox-label-pairK, .checkbox-label-pairSP {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 0;
      margin-left: 0;
      margin-top: 0.3rem;
    }
  
    .label, .labelR, .labelE, .labelS, .labelH, .labelK, .labelSP {
      font-size: 0.9rem;
      margin-left: 0.2rem;
      margin-right: 0.3rem;
    }
  
    .video-select {
      left: 50%;
      transform: translateX(-50%);
      top: 0.5rem;
    }
  
    .option-button-container {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  
    table, th, td {
      font-size: 0.7rem; /* Reduce font size for better readability */
    }
  
    .backangle-display {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.7rem;
    }
  }