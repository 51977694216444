.custom-alert-overlay {
    position: fixed;
    top: -40rem;
    left: 0;
    width: 100%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.custom-alert-box {
    background: black;
    padding: 50px;
    width: 25rem;
    height: 5rem;
    border-radius: 5px;
    border: 3px solid white;
}

.custom-alert-box p {
    margin: 0 0 20px 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-alert-box button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 3px solid white;
}

@media (min-width: 900px) and (max-width: 1280px) and (orientation: landscape) {

.custom-alert-overlay {
    position: fixed;
    top: -25rem;
    left: 0;
    width: 100%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.custom-alert-box {
    background: black;
    padding: 50px;
    width: 20rem;
    height: 3rem;
    border-radius: 5px;
    border: 3px solid white;
}

.custom-alert-box p {
    margin: 0 0 0 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-alert-box button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 3px solid white;
}
}