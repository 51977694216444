.personal-info-agreement-container {
    background-color: white;
    color: black;
    padding: 20px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
  }
  
  .personal-info-agreement-container h1,
  .personal-info-agreement-container h2 {
    text-align: left;
    color: #333;
  }
  
  .personal-info-agreement-container p,
  .personal-info-agreement-container ul {
    font-size: 0.9rem; /* Reduced font size for better readability */
    line-height: 1.6;
    color: #555;
  }
  
  .personal-info-agreement-container ul {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .personal-info-agreement-container ul li {
    margin-bottom: 10px;
  }
  
  .personal-info-agreement-container ul li ul {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .personal-info-agreement-container ul li ul li {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .personal-info-agreement-container ul li ul li:before {
    content: '•';
    color: #333;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  
  .personal-info-agreement-container ul li ul li ul li {
    list-style-type: circle;
    margin-left: 20px;
  }
  
  .personal-info-agreement-container p {
    margin-bottom: 20px;
  }
  