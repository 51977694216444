.privacy-policy-container {
    background-color: #fff;
    color: #333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
  }
  
  .privacy-policy-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .privacy-policy-container h3 {
    font-size: 1.2em;
    margin-top: 15px;
  }
  
  .privacy-policy-container p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-policy-container li {
    margin-bottom: 10px;
  }
  
  .privacy-policy-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container table th, 
  .privacy-policy-container table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 1rem;
  }
  
  .privacy-policy-container table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .privacy-policy-container table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .privacy-policy-container table tr:hover {
    background-color: #f1f1f1;
  }
  
  .privacy-policy-container .social-media-links a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy-container .social-media-links a:hover {
    text-decoration: underline;
  }
  