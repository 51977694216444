  .title{
    color:white;
    font-size: 3rem;
  }

  .title button{
    color:white;
    width: 20rem;
    background-color: red;
    cursor: pointer;
  }

  .member-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 10rem;
  }
  .member-list button{
    width: 5rem;
    height: 3rem;
    font-size: 1rem;
    margin-left: 8rem;
  }
  
  .member-list p{
    text-align: center;
    }

  .member-item {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 1em;
    margin-bottom: 2em; /* Adjust as needed */
    border-radius: 0.5em;
    width: 20rem;
  }
  .member-item p {
    font-size: 1rem; /* 16px if base font size is 16px */
    margin-bottom: 0.5em;
  }
  .member-item h3 {
    margin-top: 0;
    color:black;
    font-size: 1.5rem; /* 24px if base font size is 16px */
  }
  .member-item h3:hover {
    text-decoration: underline; /* Adds underline on hover */
  }

  .member-item.no-members {
    text-align: center;
  }
  .title button:hover{
    background-color: #0056b3;
  }

  .Mylabel2 {
    font-size: 3.75rem; /* 60px equivalent */
    color: white;
    margin-top: 0.3125rem; /* 5px equivalent */
    margin-right: 0rem; /* -100px equivalent */
  }
  .Mylabel2 label{
    margin-left: 28rem;
  }

  @media (min-width: 1180px) and (max-width: 1400px) and (orientation: landscape) {

    .Mylabel2 label{
      margin-left: 0rem;
    }
  }