body {
  background-color: black;
}

.startPageContainer {
  background-size: cover;
  background-position: center;
  height: 100vh; /* Adjust to viewport height */
  width: 100vw;
  z-index: 5;
}

.startPageContainer video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 컨테이너를 커버하도록 설정 */
  object-position: center; /* 비디오 중앙 정렬 */
  z-index: 5;
}

button {
  padding: 0.625rem 1.25rem; /* Change in rem units */
  font-size: 1.125rem; /* Change in rem units */
}

.start-text {
  position: absolute;
  bottom: 80%; /* Adjust relative position */
  left: 90%; /* Adjust relative position */
  font-size: 2rem; /* Adjusted to rem unit */
  transform: translate(-50%, 0); /* center align */
  min-width: 20rem;
  z-index: 10;
}

.start-text button {
  background-color: white; /* 버튼 배경을 흰색으로 설정 */
  color: #32CD32; /* 버튼 폰트를 연두색으로 설정 */
  border: 2px solid black; /* 버튼 테두리를 검정색으로 설정 */
  padding: 10px 20px; /* 버튼 안의 텍스트 패딩 설정 */
  font-size: 16px; /* 폰트 크기 설정 */
  cursor: pointer; /* 마우스 커서가 올라갔을 때 포인터로 변경 */
  transition: background-color 0.3s ease; /* 배경색 전환 효과 */
}

.start-text a {
  text-decoration: none;
  color: white;
}

.start-text button:hover {
  background-color: gray; /* 호버 시 배경색을 약간 회색으로 변경 */
}

@media (min-width: 900px) and (max-width: 1280px) and (orientation: landscape) {
  .startPageContainer {
    height: 100vh; /* Example: Adjust height for landscape viewing */
  }

  .start-text {
    font-size: 1.25rem; /* Slightly larger font size for landscape */
    bottom: 75%;
    left: 85%;
  }
}

@media (max-width: 768px) {
  .startPageContainer {
    height: 50vh;
  }

  .start-text {
    margin-top: 5vh;
    font-size: 1rem; /* Smaller font size for tablets */
  }
}

@media (max-width: 600px) {
  .startPageContainer {
    background-color: black; /* 검정 배경 */
    height: 100vh; /* 전체 높이 */
  }

  .startPageContainer video {
    display: none; /* 비디오 숨김 */
  }

  .start-text {
    display: none; /* 텍스트 숨김 */
  }
}
