body {
    background-color: black;
  }
  
  .Mythumbnail-container,
  .bookmark-container,
  .public-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.25rem; /* 20px equivalent */
  }

  .bookmark-container button{
    background-color: red;
    max-width: 5rem;
    margin-right: -5rem;
  }
  
  .Mythumbnail-item,
  .bookmarkthumbnail-item,
  .public-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.25rem; /* 20px equivalent */
  }
  
  .Mythumbnail-title {
    margin-top: 0.625rem; /* 10px equivalent */
    color: white;
    font-size: 1.25rem; /* 20px equivalent */
  }
  
  .Mylabel,
  .bookmark-label,
  .public-label {
    font-size: 3.75rem; /* 60px equivalent */
    color: white;
    margin-top: 0.3125rem; /* 5px equivalent */
    margin-right: 0rem; /* -100px equivalent */
  }

  .Mylabel label,
  .bookmark-label label,
  .public-label label{
    margin-left: 43.5rem;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 31.25rem;
    padding: 1.875rem; /* 30px equivalent */
    background-color: white;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem; /* 10px equivalent */
    z-index: 1000;
  }
  
  .popup h3 {
    margin-top: 0;
    color: #333;
  }
  
  .popup p {
    color: #555;
    font-size: 1.25rem; /* 20px equivalent */
  }
  
  .popup button {
    display: block;
    width: 100%;
    padding: 0.625rem 0; /* 10px equivalent */
    margin-top: 1.25rem; /* 20px equivalent */
    border: none;
    background-color: black;
    color: white;
    border-radius: 0.3125rem; /* 5px equivalent */
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: gray;
  }

  .preview-container{
    float: right;
    position: absolute;
    top: 10rem;
    right: 10rem;
  }
  .preview-container,
  .react-player {
    width: 20rem;
    height: 40rem; 
  }

  /* 페이지네이션 컨트롤러 스타일 */

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  gap: 1rem;
  cursor: pointer;
}

.pagination-controls span {
  font-size: 1.5rem; /* 페이지 번호 텍스트 크기 */
}

.pagination-controls1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
}

.pagination-controls1 button {
  background-color: #f0f0f0; /* 버튼 배경 색상 */
  border: 1px solid #d1d1d1; /* 버튼 테두리 색상 */
  color: #333; /* 버튼 텍스트 색상 */
  cursor: pointer; /* 커서 모양을 손가락 모양으로 */
  margin: 0 50rem; /* 좌우 마진 */
  transition: background-color 0.3s ease; /* 배경 색상 변화 애니메이션 */
}

.pagination-controls1 button:hover {
  background-color: #e0e0e0; /* 버튼 호버 시 배경 색상 */
}

.pagination-controls1 button:disabled {
  background-color: #f9f9f9; /* 버튼 비활성화 시 배경 색상 */
  color: #ccc; /* 버튼 비활성화 시 텍스트 색상 */
  cursor: default; /* 버튼 비활성화 시 커서 모양 */
}
  
  /* Tablet devices */
  @media (max-width: 768px) {
    .Mylabel,
    .bookmark-label,
    .public-label  {
      font-size: 1.5rem;
    }
  
    .Mythumbnail-title {
      font-size: 1rem;
    }
  
    .popup{
      width: 50%; /* Use percentage for responsive width */
      padding: 1rem; /* Adjust padding */
    }
    .popup button {
      padding: 0.15rem 0; /* 10px equivalent */
      margin-top: 0.75rem; /* 20px equivalent */
      border: none;
      border-radius: 0.3125rem; /* 5px equivalent */
      cursor: pointer;
    }
    .popup p {
      font-size: 0.625rem;
    }
  }
  
  @media (min-width: 1180px) and (max-width: 1400px) and (orientation: landscape) {
    .Mylabel,
    .bookmark-label,
    .public-label {
      font-size: 3rem; /* Slightly smaller than the original for landscape */
    }

    .Mylabel label,
    .bookmark-label label,
    .public-label label{
      margin-left: 24rem;
    }
  
    .Mythumbnail-title {
      font-size: 1.125rem; /* Adjusted for better readability in landscape */
    }
    
    .popup {
      width: 30%; /* Adjusted width for landscape */
      height: 80%;
      padding: 1rem; /* Adjusted padding for landscape */
    }

    .popup h3 {
      margin-top: 0;
      font-size: 2rem;
      color: #333;
    }
    
    .popup p {
      font-size: 0.875rem; /* Adjusted for better readability in landscape */
    }

    .popup button {
      display: block;
      width: 100%;
      padding: 0.25rem 0; /* 10px equivalent */
      margin-top: 0.5rem; /* 20px equivalent */
      border: none;
      background-color: black;
      color: white;
      border-radius: 0.3125rem; /* 5px equivalent */
      cursor: pointer;
      font-size: 1rem;
    }

      /* 페이지네이션 컨트롤러 스타일 */
    .pagination-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      margin-top: -1rem;
    }

    .pagination-controls button {
      background-color: #f0f0f0; /* 버튼 배경 색상 */
      border: 1px solid #d1d1d1; /* 버튼 테두리 색상 */
      color: #333; /* 버튼 텍스트 색상 */
      cursor: pointer; /* 커서 모양을 손가락 모양으로 */
      margin: 0 20rem; /* 좌우 마진 */
      min-width: 5rem;
      transition: background-color 0.3s ease; /* 배경 색상 변화 애니메이션 */
    }

    .pagination-controls button:hover {
      background-color: #e0e0e0; /* 버튼 호버 시 배경 색상 */
    }

    .pagination-controls button:disabled {
      background-color: #f9f9f9; /* 버튼 비활성화 시 배경 색상 */
      color: #ccc; /* 버튼 비활성화 시 텍스트 색상 */
      cursor: default; /* 버튼 비활성화 시 커서 모양 */
    }

    .pagination-controls span {
      font-size: 1rem; /* 페이지 번호 텍스트 크기 */
    }

    .pagination-controls1 button {
      background-color: #f0f0f0; /* 버튼 배경 색상 */
      border: 1px solid #d1d1d1; /* 버튼 테두리 색상 */
      color: #333; /* 버튼 텍스트 색상 */
      cursor: pointer; /* 커서 모양을 손가락 모양으로 */
      margin: 0 50rem; /* 좌우 마진 */
      min-width: 5rem;
      transition: background-color 0.3s ease; /* 배경 색상 변화 애니메이션 */
    }
  }

  /* Mobile devices */
  @media (max-width: 600px) {
    .Mylabel,
    .bookmark-label,
    .public-label {
      font-size: 1.5rem;
    }
  
    .Mythumbnail-title {
      font-size: 0.875rem;
    }
  
    .popup {
      width: 80%; /* Use percentage for responsive width */
    }
  
    .popup p {
      font-size: 0.75rem;
    }
  }