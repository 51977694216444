.service-terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .service-terms-container h1, 
  .service-terms-container h2, 
  .service-terms-container h3 {
    color: #333;
  }
  
  .service-terms-container p {
    line-height: 1.6;
    color: #555;
  }
  
  .service-terms-container ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #555;
  }
  
  .service-terms-container li {
    margin-bottom: 10px;
  }
  
  .service-terms-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .service-terms-container table th, 
  .service-terms-container table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 1rem;
  }
  
  .service-terms-container table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .service-terms-container table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .service-terms-container table tr:hover {
    background-color: #f1f1f1;
  }
  
  .service-terms-container .social-media-links a {
    color: #007bff;
    text-decoration: none;
  }
  
  .service-terms-container .social-media-links a:hover {
    text-decoration: underline;
  }
  